<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
export default {
  name: 'guild-not-authenticated',
  components:{
    LottieAnimation
  }
}
</script>

<template>
<div class="relative flex items-center justify-center w-full h-full">
    <a class="flex items-center justify-center bg-discord h-50px w-56 rounded-full hover:bg-button" href="/oauth2/login">
    <lottie-animation style="margin:0 !important;" path="static/animations/lf30_editor_eeynmzw5.json" :width="38" :height="38"/>
      <span class="pl-2 font-semibold text-white select-none">Login with Discord</span>
    </a>
    </div>
</template>