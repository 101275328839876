export default [{
        name: 'Home',
        path: '/',
        component: require('@/components/Home').default,
    },
    {
        path: '/server/:name',
        component: require('@/components/Guild').default,

    },
    {
        name: 'NotFound',
        path: '/:pathMatch(.*)*',
        component: require('@/components/PageNotFound').default,
    },
]