<script>
import getGuild from "../scripts/utils"
import GuildAuthenticated from "./GuildAuthenticated.vue"
import GuildNotAuthenticated from "./GuildNotAuthenticated.vue"
import { PublicKey } from "@solana/web3.js";
import { Metadata } from "@metaplex-foundation/mpl-token-metadata";
import axios from "axios"
import { useWorkspace } from '@/composables'
import { useToast } from "vue-toastification";

export default {
  name: 'discordGuild',
  setup(){
    const {connection} = useWorkspace();
    const toast = useToast();
    return{
      connection,
      toast,
    }
  },
  data(){
    return {
      guildInfo: {},
      collections: [],
      authenticated: null,
      loadedAccountNFTs: false,
      styles: {},
      accountNFTs:{},
      walletDropdown:{},
      accountDropdown: false,
      walletsLoaded: false,
      avatar: "",
    }
  },
  beforeRouteEnter(to, from, next) {
    getGuild(to.params.name, (err, data) => {
      if (data.guild) {
        next(vm => vm.setData(err, data))
      } else {
        console.log("run")
        next( {
          name: 'NotFound',
          // Match the path of your current page and keep the same url...
          params: { pathMatch: to.path.split('/').slice(1) },
          // ...and the same query and hash.
          query: to.query,
          hash: to.hash,
        })
      }
    })
  },
  beforeRouteUpdate(to, from, next) {
    this.guildInfo = null;
    this.collections = null;
    this.authenticated = null;
    this.loadedAccountNFTs = false;
    this.walletsLoaded = false;
    getGuild(to.params.name, (err, data) => {
      this.setData(err, data);
      next();
    });
  },
  methods: {
    setData(err, data) {
      if (err) {
        console.error(err);
      } else {
        if (data.guild){
          this.guildInfo = data.guild;
          this.collections = data.guild.collections;
          this.authenticated = data.user;
          if (this.guildInfo.backgroundImg) {
            this.styles = {backgroundImage:`url(${this.guildInfo.backgroundImg})`, color:this.guildInfo.textColour}
            }
          else {
            this.styles = {background:`white`, color:this.guildInfo.textColour}
          }
          if (this.authenticated){
            if (this.authenticated.avatar){
              this.avatar = `https://cdn.discordapp.com/avatars/${this.authenticated.id}/${this.authenticated.avatar}.png?size=512`
            }
            else {
              this.avatar = `https://cdn.discordapp.com/embed/avatars/${this.authenticated.discriminator%5}.png`
            }
          axios.post('/api/memberrefreshwallets', {
             "guildObjectID": this.guildInfo._id
          })
          .then(response => {
            if ("wallets" in response.data) {
              this.authenticated.wallets = response.data.wallets;
              if (response.data.addedNewNFTs){
                this.toast.success("Detected New NFT(s) for this server in your wallet(s) 🤟 You should get your roles within 1 Minute", {toastClassName: "verifiedMessage"});
              }
            }
          })
          .catch(() => {
            console.log("Failed to load wallets");
          });
          }
        }
      }
    },
    addWallet (val, nftsAdded){
      this.authenticated.wallets = val;
      if (nftsAdded){
        this.toast.success("You're verified 🤟 You should get your roles within 1 Minute", {toastClassName: "verifiedMessage"});
      }
      else {
        this.toast.success("We've added your wallet, but couldn't find any valid NFTs 🧐", {toastClassName: "verifiedMessage"});
      }
    },
    removeWallet (data) {
      for(let i = 0; i < this.authenticated.wallets.length; i++){
          if (this.authenticated.wallets[i].walletAddress === data){
            this.authenticated.wallets.splice(i, 1);
          }
        }
    },
    async getNFTdata(nftData, collection=null){
      try {
        var data = Object.keys(nftData).map((key) => nftData[key]);
        let arr = [];
        let n = data.length;
        for (let i = 0; i < n; i++) {
          await axios.get(data[i].data.uri)
          .then(response => {
            response.data.mint = data[i].mint;
            if (collection){
              if (response.data.collection.name === collection){
                arr.push(response.data);
              }
            }
            else {
              arr.push(response.data);
            }
          })
          .catch(e => {
            console.log(e);
          })
        }
        return arr;
      } 
      catch (error) {
        console.log(error);
      }
    },
    async loadWalletsNFTs(val) {
      if (Object.keys(val).length !== 0){
        for (let i = 0; i < val.length; i++){
          this.accountNFTs[val[i].walletAddress] = [];
          this.walletDropdown[val[i].walletAddress] = false
          for (let j = 0; j < val[i].nfts.length; j++){
            const mintPubkey = new PublicKey(val[i].nfts[j]);
            const tokenmetaPubkey = await Metadata.getPDA(mintPubkey);
            const nft = await Metadata.load(this.connection, tokenmetaPubkey);
            const nftdata = await this.getNFTdata([nft.data]);
            this.accountNFTs[val[i].walletAddress].push(nftdata[0]);
          }
        }
        this.loadedAccountNFTs = true;
      }
    },
    WalletAddressClicked(val) {
      this.walletDropdown[val] = !this.walletDropdown[val];
    },
    handleEscape(e) {
      if (e.key === "Esc" || e.key === "Escape") {
        this.accountDropdown = false;
      }
    }
  },
  created() {
    document.addEventListener("keydown", this.handleEscape);
  },
  beforeUnmount() {
    document.removeEventListener("keydown", this.handleEscape);
  },
  components: {
    GuildAuthenticated,
    GuildNotAuthenticated,
  },
  watch: {
    "authenticated.wallets":  async function(val) {
        this.loadedAccountNFTs = false;
        await this.loadWalletsNFTs(val);
    },
  },
}
</script>

<template>
<div class="w-full h-full top-0 left-0 bg-cover bg-fixed bg-top" :style="this.styles">
<div class="overflow-y-scroll h-full w-full block">
<button v-if="accountDropdown" @click="accountDropdown = false" tabindex="-1" class="absolute inset-0 h-full w-full cursor-default z-10"></button>
<div class="absolute top-5 left-5 select-none">
      <a :href="`/server/${this.$route.params.name}`" class="relative z-10 block h-24 w-auto overflow-hidden">
        <img draggable="false" class="h-24 w-auto" src="../assets/logo.png">
        </a>
</div>
<div class="flex flex-col items-center justify-center pt-20 relative">
      <img draggable="false" class="h-44 w-44 rounded-full overflow-hidden select-none" :src="this.guildInfo.img">
      <h1 class="text-center font-bold text-5xl mt-1 mb-2 text-inherit">{{this.guildInfo.name}}</h1>
      </div>
  <div class="pt-28" v-if="!this.authenticated">
    <guild-not-authenticated></guild-not-authenticated>
  </div>
  <div class="static" id="loggedIn" v-else-if="this.authenticated">
    <guild-authenticated :guildInfo="this.guildInfo" :authenticated="this.authenticated" :avatar="this.avatar" :collections="this.collections" :accountNFTs="this.accountNFTs" :walletDropdown="this.walletDropdown" :loadedAccountNFTs="this.loadedAccountNFTs" :accountDropdown="accountDropdown" @unAuth="this.authenticated = null" @addWallet="addWallet" @avatarClicked="accountDropdown=!accountDropdown" @removeWallet="removeWallet" @WalletAddressClicked="WalletAddressClicked"></guild-authenticated>
    </div>
    </div>
    </div>
</template>