<script>
import { toRefs } from "vue";
import { useWallet } from 'solana-wallets-vue';
import { computed, defineComponent } from "vue";
import { WalletConnectButton, WalletModalProvider, WalletDisconnectButton } from "solana-wallets-vue";


export default defineComponent({
    name: 'custom-wallet-button',
    components: {
        WalletConnectButton,
        WalletModalProvider,
        WalletDisconnectButton
    },
    props: {
        featured: { type: Number, default: 3 },
        container: { type: String, default: 'body' },
        logo: String,
        dark: Boolean,
    },
    setup (props) {
        const { featured, container, logo, dark } = toRefs(props);
        const { publicKey, wallet } = useWallet();
        const publicKeyBase58 = computed(() => publicKey.value?.toBase58());
        const scope = {
            featured,
            container,
            logo,
            dark,
            wallet,
            publicKeyBase58
        }
        return {
            scope,
            ...scope,
        };
    },
});
</script>

<template>
    <wallet-modal-provider :featured="featured" :container="container" :logo="logo" :dark="dark">
    <template #default="modalScope">
      <slot v-bind="{ ...modalScope, ...scope }">
        <button v-if="!wallet" class="swv-button swv-button-trigger" @click="modalScope.openModal">
          Select Wallet
        </button>
        <wallet-connect-button v-else-if="!publicKeyBase58"></wallet-connect-button>
    <wallet-disconnect-button v-else></wallet-disconnect-button>
      </slot>
    </template>

    <template #overlay="modalScope">
      <slot name="modal-overlay" v-bind="{ ...modalScope, ...scope }"></slot>
    </template>
    <template #modal="modalScope">
      <slot name="modal" v-bind="{ ...modalScope, ...scope }"></slot>
    </template>
    </wallet-modal-provider>
</template>
