<template>
  <div>
  <wallet-modal-provider>
      <wallet-modal-button v-if="!iswallet">
        <slot></slot>
    </wallet-modal-button>
    <wallet-connect-button v-else-if="!base58">
        <slot></slot>
    </wallet-connect-button>
      <wallet-disconnect-button v-else>
        <slot></slot>
      </wallet-disconnect-button>
    </wallet-modal-provider>
  </div>
</template>

<script>
import { WalletDisconnectButton, WalletModalProvider, WalletModalButton, WalletConnectButton } from '@solana/wallet-adapter-vue-ui'
import { computed } from "vue";
import { useWorkspace } from '@/composables'
export default {
  setup(){
    const {wallet} = useWorkspace();
    const base58 = computed(() => wallet.publicKey.value?.toBase58());
    return{
      iswallet: wallet.connected,
      base58,
    }
  },
  name: 'HelloWorld',
  components: {
    WalletDisconnectButton,
    WalletModalProvider,
    WalletModalButton,
    WalletConnectButton,
  }
}
</script>