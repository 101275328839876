import './main.css'
import 'solana-wallets-vue/styles.css';
import { createApp } from "vue"
import App from "./App.vue"
import { createRouter, createWebHistory } from "vue-router"
import routes from "./routes"
import Toast, { POSITION, TYPE } from "vue-toastification";
import SolanaWallets from 'solana-wallets-vue';
import "vue-toastification/dist/index.css";
import { PhantomWalletAdapter, SlopeWalletAdapter, SolflareWalletAdapter, TorusWalletAdapter, SolletWalletAdapter, SolletExtensionWalletAdapter } from '@solana/wallet-adapter-wallets';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';

const router = createRouter({
    history: createWebHistory(),
    routes,
})
const network = WalletAdapterNetwork.Mainnet;

const walletOptions = {
    wallets: [
        new PhantomWalletAdapter(),
        new SlopeWalletAdapter(),
        new SolflareWalletAdapter({ network: network }),
        new TorusWalletAdapter(),
        new SolletWalletAdapter({ network: network }),
        new SolletExtensionWalletAdapter({ network: network }),

    ],
    autoConnect: true,
}

const options = {
    position: POSITION.BOTTOM_RIGHT,
    [TYPE.SUCCESS]: {
        timeout: 3000,
        hideProgressBar: false,
    }
}

createApp(App).use(router).use(Toast, options).use(SolanaWallets, walletOptions).mount('#app')