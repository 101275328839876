import { inject, provide } from 'vue'
//import { useWallet } from '@solana/wallet-adapter-vue'
import { Connection } from '@solana/web3.js'

const workspaceSymbol = Symbol();

export const useWorkspace = () => inject(workspaceSymbol);

export const initWorkspace = () => {
    //const wallet = useWallet();
    const connection = new Connection("https://rpc.ankr.com/solana", 'confirmed');

    provide(workspaceSymbol, {
        //wallet,
        connection,
    });
}
