<script>
import WorkspaceProvider from '@/components/WorkspaceProvider'

export default {
  name: 'HelloWorld',
  components: {
    WorkspaceProvider
  }
}
</script>

<template>
  <workspace-provider>
        <router-view></router-view>
        </workspace-provider>
</template>