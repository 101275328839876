<script>
import axios from "axios"
import { useWorkspace } from '@/composables'
import {getParsedNftAccountsByOwner} from "@nfteyez/sol-rayz";
import { sign } from 'tweetnacl';
import bs58 from 'bs58';
import CustomWalletButton from './CustomWalletButton.vue'
import { useWallet } from 'solana-wallets-vue';
import { useToast } from "vue-toastification";
import { PublicKey } from "@solana/web3.js";
import { Metadata } from "@metaplex-foundation/mpl-token-metadata";
import * as web3 from '@solana/web3.js';

export default {
  name: 'guild-authenticated',
  props: {guildInfo:{
      type: Object
  }, collections: {
      type: Array
  }, authenticated: {
      type: Object
  }, loadedAccountNFTs :{
      type: Boolean
  }, walletDropdown :{
      type: Object
  }, accountNFTs :{
      type: Object
  }, accountDropdown :{
    type: Boolean
  }, avatar :{
    type: String
  }
  },
  setup(){
    const {connection} = useWorkspace();
    const {publicKey, connected, signMessage, disconnect, signTransaction} = useWallet();
    const toast = useToast();
    let loadWallet;
    return{
      publicKey,
      iswallet: connected,
      signMessage,
      walletDisconnect: disconnect,
      connection,
      toast,
      loadWallet,
      signTransaction,
    }
  },
  data(){
    return {
      walletNFTs: [],
      loadedNFTs: false,
      selectedNFTs: {},
      noNFTS: false,
      background: {},
      NFTsToSend: {},
      ledger: false,
    }
  },
  methods: {
    makeSingle(generator) {
        let globalNonce;
        return async function(...args) {
          const localNonce = globalNonce = new Object();

          const iter = generator(...args);
          let resumeValue;
          for (;;) {
            const n = iter.next(resumeValue);
            if (n.done) {
              return n.value;  // final return value of passed generator
            }

            // whatever the generator yielded, _now_ run await on it
            resumeValue = await n.value;
            if (localNonce !== globalNonce) {
              return;  // a new call was made
            }
            // next loop, we give resumeValue back to the generator
          }
        };
      },
      async getNFTs(address){
        try {
          let nftArray = await getParsedNftAccountsByOwner({
            publicAddress: address,
            connection: this.connection
          });
          return nftArray;
        }
        catch (error) {
          console.log("Error thrown, while fetching NFTs", error.message);
        }
    },
    async getNFTdata(nftData, collection=null){
      try {
        var data = Object.keys(nftData).map((key) => nftData[key]);
        let arr = [];
        let n = data.length;
        for (let i = 0; i < n; i++) {
          await axios.get(data[i].data.uri)
          .then(response => {
            response.data.mint = data[i].mint;
            if (collection){
              if ("collection" in response.data) {
                if ("name" in response.data.collection) {
                  if (response.data.collection.name === collection){
                    arr.push(response.data);
                  }
                }
                else {
                  arr.push(response.data);
                }
              }
              else {
                arr.push(response.data);
              }
            }
            else {
              arr.push(response.data);
            }
          })
          .catch(e => {
            console.log(e);
          })
        }
        return arr;
      } 
      catch (error) {
        console.log(error);
      }
    },
    async signMessageFromNFTS(data, nonce){
      try{
        if (!this.publicKey) throw new Error('Wallet not connected!');
        if (!this.signMessage) throw new Error('Wallet does not support message signing!');
        const message = "Please sign this message to verify your\n NFTs with NiftyPass\n\n Security Stuff:\n\n" + nonce;
        const encodedMessage = new TextEncoder().encode(message);
        const signature = await this.signMessage(encodedMessage);
        if (!sign.detached.verify(encodedMessage, signature, this.publicKey.toBytes())) {
          console.error("Invalid signature!");
          return false;
        }
        return {"mints" : data, "guild" : this.guildInfo._id, "walletAddress" : this.publicKey.toString(), "signature" : bs58.encode(signature), "ledger": false};
      } 
      catch (error) {
        console.log(`Signing failed: ${error?.message}`);
        return false;
      }
    },
    async approveTansaction(data, nonce){
      /* let transaction = new web3.Transaction().add(
        web3.SystemProgram.transfer({
        fromPubkey: this.publicKey,
        toPubkey: this.publicKey,
        lamports: web3.LAMPORTS_PER_SOL * 0,
        }),
      ); */
      let transaction = new web3.Transaction().add(
        new web3.TransactionInstruction({data: Buffer.from(nonce), programId: new PublicKey("MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"), keys: [{pubkey: this.publicKey, isSigner: true, isWritable: false}]})
      )
      transaction.recentBlockhash = (await this.connection.getRecentBlockhash()).blockhash
      //transaction.recentBlockhash = bs58.encode(Buffer.from("The Latest Blockhash thats is 32"))
      transaction.feePayer = this.publicKey
      try{
        const signedTransaction = await this.signTransaction(transaction)
        const message = transaction.serializeMessage()
        const signature = signedTransaction.signatures[0].signature
        return {"mints" : data, "guild" : this.guildInfo._id, "walletAddress" : this.publicKey.toString(), "signature" : bs58.encode(signature), "message": bs58.encode(message), "ledger": true};
      }
      catch(error){
        console.log(error);
        return false;
      }
    },
    async addWallet(data){
      await axios.post('/api/addwallet', {
        ...data
      })
      .then(response => {
        this.walletNFTs = [];
        this.NFTsToSend = {};
        this.selectedNFTs = {};
        this.loadedNFTs = false;
        this.noNFTS = false;
        this.ledger = false;
        this.walletDisconnect();
        if ("msg" in response.data){
          if (response.data.msg === "No NFTs added")
            this.$emit("addWallet", response.data.wallets, false)
        }
        else if ("wallets" in response.data){
            this.$emit("addWallet", response.data.wallets, true)
        }
      })
      .catch(error => {
        if (error.response.status === 401){
            this.$emit("unAuth");
        }
      });
    },
    async deletewallet(data){
      let dataObj = {};
      dataObj["walletAddress"] = data ;
      await axios.post('/api/deletewallet', {
        ...dataObj
      })
      .then(() => {
        this.$emit("removeWallet", data);
      })
      .catch(error => {
        if (error.response.status === 401){
          this.$emit("unAuth");
        }
      });
    },
    async submitForm(){
      let nfts = {};
      if (Object.keys(this.selectedNFTs).length !== 0){
        for (const collection in this.NFTsToSend) {
          let temp = {"main": null};
          temp.main = this.selectedNFTs[collection];
          nfts[collection] = temp
        }
      }
      const nonce = (await axios.get("/api/getnonce")).data.nonce
      let data = null;
      if (!this.ledger){
        data = await this.signMessageFromNFTS(nfts, nonce);
      }
      else if (this.ledger){
        data = await this.approveTansaction(nfts, nonce);
      }
      if (data){
        await this.addWallet(data);
      }
    },
    *walletLoaded(val) {
      this.walletNFTs = [];
      this.NFTsToSend = {};
      this.selectedNFTs = {};
      this.loadedNFTs = false;
      this.noNFTS = false;
      this.ledger = false;
      if (val === true) {
        let nftsArray = yield this.getNFTs(this.publicKey.toString());
        nftsArray.push(...yield this.getStakedNFTs())
        let nftData = this.sortNFTs(nftsArray)
        for (let i = 0; i < this.collections.length; i++){
          let nfts = []
          for (let j = 0; j < this.collections[i].updateAuthority.length; j++) {
            if (this.collections[i].updateAuthority[j] in nftData) {
              let temp = yield this.getNFTdata(nftData[this.collections[i].updateAuthority[j]], this.collections[i].name);
              nfts.push(...temp)
            }
          }
          if (nfts.length !== 0){
            this.walletNFTs.push({"_id": this.collections[i]._id, "collection" : this.collections[i].displayName, "items" : nfts});
            if (this.guildInfo.mainNFT) {
              this.selectedNFTs[this.collections[i]._id] = nfts[0].mint;
            }
            this.NFTsToSend[this.collections[i]._id] = []
            for (let k = 0; k < nfts.length; k++){
              this.NFTsToSend[this.collections[i]._id].push(nfts[k].mint);
            }
          }
        }
        if (Object.keys(this.walletNFTs).length !== 0){
          this.loadedNFTs = true;
        }
        else {
          this.noNFTS = true;
        }
      }
    },
    async getStakedNFTs() {
      let nftArray = []
      let collections = []
      for (let i = 0; i < this.guildInfo.collections.length; i++) {
        if (this.guildInfo.collections[i].staking.isStaking) {
          collections.push(this.guildInfo.collections[i]);
        }
      }
      if (!(collections.length === 0)) {
        await axios.post('/api/getstakednfts', {
          "collections" : collections, "walletAddress": this.publicKey.toString()
        })
        .then (async response => {
          const stakedNFTs = response.data.stakedNFTs;
          for (let i = 0; i < stakedNFTs.length; i++) {
            const mintPubkey = new PublicKey(stakedNFTs[i]);
            const tokenmetaPubkey = await Metadata.getPDA(mintPubkey);
            const nft = await Metadata.load(this.connection, tokenmetaPubkey);
            nftArray.push(nft.data);
          }
        })
        .catch(error => {
        if (error.response.status === 401){
          this.$emit("unAuth");
        }
      });
      }
      return nftArray
    },
    sortNFTs(nftArray){
      const groups = nftArray.reduce((groups, item) => {
            const group = (groups[item.updateAuthority] || []);
            group.push(item);
            groups[item.updateAuthority] = group;
            return groups;
          }, {});
      return groups
    }
  },
  watch: {
    iswallet: function(val) {
      this.loadWallet(val);
    },
  },
  mounted() {
    this.loadWallet = this.makeSingle(this.walletLoaded);
    this.loadWallet(this.iswallet);
  },
  components: {
    CustomWalletButton,
  },
}
</script>



<template>
     <div class="absolute top-5 right-5">
      <button @click="$emit('avatarClicked')" class="relative z-20 block h-24 w-24 rounded-full overflow-hidden">
        <img draggable="false" class="rounded-full" :src="this.avatar">
        </button>
    <div v-if="accountDropdown" class="absolute right-0 mt-2 w-24 bg-white rounded-lg shadow-xl hover:bg-gray-200 z-20">
      <a class="block px-2 py-3 text-center text-black" href="/oauth2/logout">Logout</a>
    </div>
    </div>
    <div class="flex justify-center flex-col items-center pt-5 relative" v-if="this.authenticated.wallets">
      <h2 class="text-center font-semibold text-3xl pb-2 text-inherit">Connected Wallets:</h2>
      <div v-for="wallet in this.authenticated.wallets" :key="wallet">
        <div class="flex items-center" v-if="loadedAccountNFTs">
          <div class="mx-auto"><button @click="$emit('WalletAddressClicked', wallet.walletAddress)">{{wallet.walletAddress}}</button><button class="font-bold" @click="deletewallet(wallet.walletAddress)">&nbsp;Remove</button></div>
        </div>
        <div class="relative mt-1 mb-1 justify-center items-center max-w-3xl shadow-inner bg-chalk rounded-2xl bg-opacity-25" v-if="walletDropdown[wallet.walletAddress]">
        <div class="w-full flex flex-wrap justify-center items-center" v-if="accountNFTs[wallet.walletAddress].length">
          <div class="w-full m-2 md:w-48 bg-zinc-50 rounded-lg shadow select-none" v-for="nft in accountNFTs[wallet.walletAddress]" :key="nft">
            <img draggable="false" class="w-full rounded-t-lg" :src="nft.image">
            <span class="p-2 text-left inline-block text-black">{{nft.name}}</span>
          </div>
          </div>
          <h4 class="flex justify-center items-center py-4 relative text-inherit" v-else>No Verified NFTs for this server in this wallet</h4>
          </div>
      </div>
      </div>
    <div class="flex justify-center items-center py-4 relative select-none">
      <custom-wallet-button dark></custom-wallet-button>
    </div>
    <div class="flex justify-center flex-col items-center pt-1 relative" v-if="this.iswallet">
      <form @submit.prevent="submitForm">
      <div v-if="this.guildInfo.mainNFT">
      <div v-for="collection in this.walletNFTs" :key="collection">
        <div class="flex flex-col items-center">
        <h3 class="text-center font-medium text-2xl pb-2 text-inherit">{{collection.collection}}</h3>
        <h4 class="text-center text-inherit">Choose your main NFT for this collection:</h4>
        </div>
        <div class="relative mt-1 mb-1 justify-center items-center max-w-3xl shadow-inner bg-chalk rounded-2xl bg-opacity-25">
          <div class="w-full flex flex-wrap justify-center items-center">
        <div class="w-full m-2 md:w-48 bg-zinc-50 rounded-lg shadow select-none" v-for="item in collection.items" :key="item">
          <label>
            <input :name="collection.collection" type="radio" v-model="selectedNFTs[collection._id]" :value="item.mint"/>
            <div class="w-full rounded-lg">
            <img draggable="false" class="w-full rounded-t-lg " :src="item.image">
            <div class="p-2 text-left inline-block text-black">{{item.name}}</div>
            </div>
          </label>
        </div>
        </div>
        </div>
        </div>
      </div>
      <div v-else>
        <div v-for="collection in this.walletNFTs" :key="collection">
        <div class="flex flex-col items-center">
        <h3 class="text-center font-medium text-2xl pb-2 text-inherit">{{collection.collection}}</h3>
        <h4 class="text-center text-inherit">NFTs:</h4>
        </div>
        <div class="relative mt-1 mb-1 justify-center items-center max-w-3xl shadow-inner bg-chalk rounded-2xl bg-opacity-25">
        <div class="w-full flex flex-wrap justify-center items-center">
          <div class="w-full m-2 md:w-48 bg-zinc-50 rounded-lg shadow select-none" v-for="item in collection.items" :key="item">
            <img draggable="false" class="w-full rounded-t-lg" :src="item.image">
            <span class="p-2 text-left inline-block text-black">{{item.name}}</span>
          </div>
          </div>
          </div>
        </div>
      </div>
      <div class="flex items-center pt-4 pb-2 select-none">
        <label for="ledger" class="mx-auto flex items-center cursor-pointer">
    <div class="relative">
      <input id="ledger" type="checkbox" class="sr-only" v-model="ledger" />
      <div class="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
      <div class="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"></div>
    </div>
    <div class="ml-3 text-inherit font-semibold">
      Using Ledger
    </div>
    <img class="w-7 h-7 pl-2" src="data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMzUgMzUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iI2ZmZiI+PHBhdGggZD0ibTIzLjU4OCAwaC0xNnYyMS41ODNoMjEuNnYtMTZhNS41ODUgNS41ODUgMCAwIDAgLTUuNi01LjU4M3oiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDUuNzM5KSIvPjxwYXRoIGQ9Im04LjM0MiAwaC0yLjc1N2E1LjU4NSA1LjU4NSAwIDAgMCAtNS41ODUgNS41ODV2Mi43NTdoOC4zNDJ6Ii8+PHBhdGggZD0ibTAgNy41OWg4LjM0MnY4LjM0MmgtOC4zNDJ6IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDUuNzM5KSIvPjxwYXRoIGQ9Im0xNS4xOCAyMy40NTFoMi43NTdhNS41ODUgNS41ODUgMCAwIDAgNS41ODUtNS42di0yLjY3MWgtOC4zNDJ6IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMS40NzggMTEuNDc4KSIvPjxwYXRoIGQ9Im03LjU5IDE1LjE4aDguMzQydjguMzQyaC04LjM0MnoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDUuNzM5IDExLjQ3OCkiLz48cGF0aCBkPSJtMCAxNS4xOHYyLjc1N2E1LjU4NSA1LjU4NSAwIDAgMCA1LjU4NSA1LjU4NWgyLjc1N3YtOC4zNDJ6IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDExLjQ3OCkiLz48L2c+PC9zdmc+"/>
  </label></div>
      <div class="flex items-center pt-4 pb-4 select-none"><button class="mx-auto h-50px rounded-full bg-green pl-5 pr-5 text-white font-semibold hover:bg-button" type="submit">Sign Message</button></div>
      </form>
    </div>
    <!--<h4 class="flex justify-center items-center pt-2 relative text-inherit" v-if="this.noNFTS">No Valid NFTs in wallet</h4>-->
</template>